import { Action } from '../types/Action'
import { Allergy } from '../db/interfaces/Allergy'
import { Consultation } from '../db/interfaces/Consultation'
import { Medicine } from '../db/interfaces/Medicine'
import { BloodGlucose } from '../db/interfaces/BloodGlucose'
import { BloodPressure } from '../db/interfaces/BloodPressure'
import { BodyMassIndex } from '../db/interfaces/BodyMassIndex'
import { HeartRate } from '../db/interfaces/HeartRate'

export enum ActionTypes {
  MEDICINE_SAVE = 'MEDICINE_SAVE',
  ALLERGY_SAVE = 'ALLERGY_SAVE',
  CONTACT_SAVE = 'CONTACT_SAVE',
  BLOOD_GLUCOSE_SAVE = 'BLOOD_GLUCOSE_SAVE',
  BLOOD_PRESSURE_SAVE = 'BLOOD_PRESSURE_SAVE',
  BODY_MASS_INDEX_SAVE = 'BODY_MASS_INDEX_SAVE',
  HEART_RATE_SAVE = 'HEART_RATE_SAVE',
  CONSULTATION_SAVE = 'CONSULTATION_SAVE',
}

export type AllergySaveAction = Action<
  typeof ActionTypes.ALLERGY_SAVE,
  Partial<Allergy>
>

export type ConstactSaveAction = Action<
  typeof ActionTypes.CONSULTATION_SAVE,
  Partial<Consultation>
>

export type MedicineSaveAction = Action<
  typeof ActionTypes.MEDICINE_SAVE,
  Partial<Medicine>
>

export type BloodGlucoseSaveAction = Action<
  typeof ActionTypes.BLOOD_GLUCOSE_SAVE,
  Partial<BloodGlucose>
>

export type BloodPressureSaveAction = Action<
  typeof ActionTypes.BLOOD_PRESSURE_SAVE,
  Partial<BloodPressure>
>

export type BodyMassIndexSaveAction = Action<
  typeof ActionTypes.BODY_MASS_INDEX_SAVE,
  Partial<BodyMassIndex>
>

export type HeartRateSaveAction = Action<
  typeof ActionTypes.HEART_RATE_SAVE,
  Partial<HeartRate>
>
