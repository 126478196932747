import { combineReducers } from 'redux'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AppState {
  online: boolean
}

const onlineReducer = (online = true) => online

export default combineReducers<AppState>({
  online: onlineReducer,
})
