import { spawn, take, all, takeEvery } from 'redux-saga/effects'
import { ActionTypes } from './actions'
import { makeCollectionUpdater } from '../util/makeDbUpdater'
import { ConsultationCollection } from '../db/consultations'
import { AllergyCollection } from '../db/allergy'
import { MedicineCollection } from '../db/medicine'
import { HeartRateCollection } from '../db/heartRate'
import { BloodPressureCollection } from '../db/bloodPressure'
import { BloodGlucoseCollection } from '../db/bloodGlucose'
import { BodyMassIndexCollection } from '../db/bodyMassIndex'
import { ContactsCollection } from '../db/contacts'

function* logSaga() {
  while (true) {
    const action = yield take()
    console.log('[Action]', action)
  }
}

const saveConsultation = makeCollectionUpdater(ConsultationCollection)

const saveAllergy = makeCollectionUpdater(AllergyCollection)

const saveMedicine = makeCollectionUpdater(MedicineCollection)

const saveHeartRate = makeCollectionUpdater(HeartRateCollection)

const saveBloodPressure = makeCollectionUpdater(BloodPressureCollection)

const saveBloodGlucose = makeCollectionUpdater(BloodGlucoseCollection)

const saveBodyMassIndex = makeCollectionUpdater(BodyMassIndexCollection)

const saveContact = makeCollectionUpdater(ContactsCollection)

export default function*() {
  yield spawn(logSaga)
  yield all([
    takeEvery(ActionTypes.CONSULTATION_SAVE, saveConsultation),
    takeEvery(ActionTypes.ALLERGY_SAVE, saveAllergy),
    takeEvery(ActionTypes.MEDICINE_SAVE, saveMedicine),
    takeEvery(ActionTypes.BLOOD_GLUCOSE_SAVE, saveBloodGlucose),
    takeEvery(ActionTypes.BLOOD_PRESSURE_SAVE, saveBloodPressure),
    takeEvery(ActionTypes.HEART_RATE_SAVE, saveHeartRate),
    takeEvery(ActionTypes.BODY_MASS_INDEX_SAVE, saveBodyMassIndex),
    takeEvery(ActionTypes.CONTACT_SAVE, saveContact),
  ])
}
