import React, { memo } from 'react'

import heroImg from '../../assets/images/home-hero-mobile.png'
import logoImg from '../../assets/images/virtualdoc-logo-colour.svg'
import heroLogo from '../../assets/images/virtualdoc-hero-logo.svg'
import heroImgDesktop from '../../assets/images/home-hero-desktop.jpg'

export const HomePageOutOfService = memo(function HomePage() {
  return (
    <div className="pattern-bg page home-page">
      <div className="header">
        <img
          style={{ width: 'auto', height: '60px' }}
          src={logoImg}
          alt="logo"
        />
      </div>
      <div className="hero-unit unselectable">
        <img
          className="hero-banner-mobile unselectable"
          src={heroImg}
          alt="banner"
        />
        <img
          className="hero-banner-desktop"
          src={heroImgDesktop}
          alt="banner"
        />

        <img src={heroLogo} className="hero-logo" alt="logo" />
        <div className="hero-text">GP Advice Line</div>
        <div className="hero-description">
          The service is no longer available. <br /> Please contact{' '}
          <a
            className="mail-link"
            href="mailto: services@greenwichpeninsula.co.uk"
          >
            Greenwich Peninsula team
          </a>{' '}
          if you have any questions
        </div>
      </div>
    </div>
  )
})
