import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import reducer from '../state/reducer'

export function configureStore() {
  const sagaMiddleware = createSagaMiddleware()
  const composeEnhancers =
    // eslint-disable-next-line
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(sagaMiddleware)),
  )

  return {
    ...store,
    // Don't start any saga inside configureStore!!!
    // sagas should be started when we need them so
    // we will not spawn sagas when we're in unit-tests
    runSaga: sagaMiddleware.run,
  }
}
