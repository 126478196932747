import React from 'react'
import { Switch, Route } from 'react-router'
import { HomePageOutOfService } from './pages/HomeOutOfService/HomePageOutOfService'

const App = () => {
  return (
    <div className="App">
      <Switch>
        <Route>
          <HomePageOutOfService />
        </Route>
      </Switch>
    </div>
  )
}

export default App
