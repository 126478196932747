/* eslint-disable @typescript-eslint/no-explicit-any */
import { call } from 'redux-saga/effects'
import { Dexie } from 'dexie'
import { AbstractEntry } from '../db/interfaces/AbstractEntry'
import { Action } from '../types/Action'

export function makeCollectionUpdater<T extends AbstractEntry>(
  collection: Dexie.Table<T, any>,
) {
  return function*(action: Action<any, T>) {
    const item = action.payload
    const id = item.id || -1
    const dbObj = yield call(() =>
      collection
        .where({
          id: typeof id === 'number' ? id : parseInt(id, 10),
        })
        .first(),
    )

    if (dbObj) {
      yield call(() =>
        collection.update(dbObj.id, {
          ...item,
          id: dbObj.id,
        }),
      )
    } else {
      yield call(() => collection.add(item))
    }
  }
}
